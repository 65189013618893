
.happenings-wrapper {
    .happenings-container {
        padding: 15vw 0 3vw;
        font-size: 1.25vw;

        .years-nav-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 3vw;

            .year-nav {
                cursor: pointer;
                font-size: 1.8vw;

                &:hover, &.isActive {
                    color: #ff4f79;
                }
            }
        }

        .article-date {
            font-size: 1.8vw;
            font-weight: 700;
            margin: 0.6vw 0;
        }

        .article-content {
            margin: 0 0 1.2vw;
        }

        .article-img {
            max-height: 30vw;
            width: auto;
        }
    }
}

@media (max-width: 768px) {

    .happenings-wrapper {
        .happenings-container {
            padding: 50vw 3vw 5vw;
            font-size: 3.6vw;

            .years-nav-container {
                .year-nav {
                    font-size: 4.2vw;
                }
            }

            .article-date {
                font-size: 4.5vw;
                margin: 6vw 0 0;
            }

            .article-content {
                margin: 0 0 3vw;
            }
        }
    }
}
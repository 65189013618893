.exhibition-wrapper-content {
  padding: 12vw 0 5vw;
}

.exhibition-component-title {
  font-size: 1.87vw;
  font-weight: 700;
  text-align: center;
  margin: 3vw 0 1vw;
}

.exhibition-component-content {
  text-align: center;
}

@media (max-width: 768px) {
  .exhibition-wrapper-content {
    padding: 43vw 0 5vw;
  }

  .exhibition-component-title {
    font-size: 5.6vw;
  }

  .exhibition-component-content {
    padding: 2vw 5vw;
  }
}

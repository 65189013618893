.content-hedaer-container {
    width: 100vw;
    position: absolute;
    left: 0;
    display: grid;
    place-content: center;
    height: 11.1vw;
    background-size: cover;

    .content-header-title {
        font-weight: 700;
        font-size: 3vw;
    }
}

@media (max-width: 768px) {
    .content-hedaer-container {
        height: 48vw;

        .content-header-title {
            font-size: 6vw;
        }
    }
}
.services-wrapper-content {
  padding: 12vw 0 5vw;
}

.service-component {
  display: flex;
  align-items: center;
  gap: 2vw;

  img {
    width: 22.73vw !important;
    height: 15.15vw !important;
  }
}

.service-component-title {
  font-size: 1.21vw;
  font-weight: bold;
}

@media (max-width: 768px) {
  .service-component {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 60% !important;
      height: 100% !important;
    }
  }

  .services-wrapper-content {
    padding: 48vw 0 5vw;
  }

  .service-component-title {
    font-size: 5.6vw;
    text-align: center;
  }

  .service-component-content {
    padding: 2vw 5vw;
    text-align: left;
  }
}

.home-banner-slider-container {
  height: 18.7vw;
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  line-height: 0;
}

.home-component-our-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .standard-body {
    display: flex;
    gap: 2vw;
    width: 100%;
  }

  span {
    margin: 0.5vw 0 0;
    text-align: center;
    color: #605a5c;
  }
}

.home-component-about-us {
  background: url("../../../public/content/images/about-us-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 3vw;
  text-align: center;
}

.home-component-exhibition {
  .standard-body {
    .home-component-exhibition-items {
      display: flex;
      gap: 4vw;
      .home-component-exhibition-item {
        width: 22.3vw;
        border-radius: 0.78vw;
        background: #eee;

        img {
          border-radius: 0.78vw 0.78vw 0 0;
        }

        .home-component-exhibition-item-content-container {
          padding: 1vw;

          .home-component-exhibition-item-title {
            font-weight: bold;
            margin: 0 0 0.53vw;
          }

          .home-component-exhibition-item-content {
            color: #605a5c;
            font-size: 1vw;
          }
        }
      }
    }
  }
}

.home-action-button {
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

@media (max-width: 768px) {
  .home-banner-slider-container {
    height: auto;
  }

  .home-component-our-service {
    .standard-body {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-gap: 2vw;
      justify-content: center;
    }

    .home-component-our-service-img {
      width: 75%;
    }

    span {
      margin: 0.5vw 0 0;
      text-align: center;
      color: #605a5c;
    }
  }

  .home-component-about-us {
    background: url("../../../public/content/images/m-about-us-background.png");
    padding: 5vw 10vw;
    font-size: 4vw;
  }

  .home-component-exhibition {
    .standard-body {
      overflow: scroll;
      margin: 4vw;

      .home-component-exhibition-items {
        width: fit-content;

        .home-component-exhibition-item {
          width: 80vw;
          border-radius: 2vw;

          img {
            border-radius: 2vw 2vw 0 0;
          }

          .home-component-exhibition-item-content-container {
            padding: 3vw;

            .home-component-exhibition-item-title {
              font-size: 3.73vw;
              margin: 3vw 0;
            }

            .home-component-exhibition-item-content {
              font-size: 2.67vw;
              line-height: 3.5vw;
            }
          }
        }
      }
    }
  }

  .home-action-button {
    height: 10vw;
    text-decoration: underline;
    color: #605a5c;
  }
}

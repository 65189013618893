$primary-color: #ff4f79;
$secondary-color: #605a5c;

html {
  overflow-x: hidden;
}

.header-logo {
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;

  img {
    height: 100%;
  }
}

.nav-bar-container {
  height: 2vw;
  background: #605a5c;
  color: #fff;
  padding: 0.5vw;
  font-size: 1vw;

  .nav-bar-tabs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;

    .nav-bar-tab {
      cursor: pointer;
      text-decoration: none;
      color: #fff;

      &.selected {
        color: $primary-color;
      }

      &.nav-bar-tab-contactUs {
        background: $primary-color;
        border-radius: 20px;
        padding: 0.4vw 1vw;
        &.selected {
          color: #fff;
        }
      }
    }
  }
}

.body-footer {
  height: 2vw;
  background: #605a5c;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;
}

.body-content {
  max-width: 75vw;
  width: 100%;
  margin: 0 auto;
  font-size: 1.2vw;
  font-family: "Montserrat", sans-serif;

  img {
    width: 100%;
  }
}

.slick-slider {
  position: relative;
  overflow: hidden;
}

.slick-track {
  display: flex;
}

.slider-dots-container {
  width: 100%;
  position: absolute;
  bottom: 1vw;
  display: flex;
  justify-content: center;
  gap: 0.5vw;
}

.slider-dots {
  width: 1vw;
  height: 1vw;
  border-radius: 100%;
  background: #605a5c99;
  cursor: pointer;
}

.slider-active {
  background: #ff4f7999;
}

.standard-component {
  margin: 2.4vw 0 0;
}

.standard-header {
  font-size: 1.5vw;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.standard-header-bottom-line {
  width: 2vw;
  border: 0.1vw solid #000;
  margin: 0.25vw 0 0;
}

.standard-body {
  margin: 2vw 0 0;
}

/* width */
::-webkit-scrollbar { width: 0.6vw; height: 0; }

/* Track */
::-webkit-scrollbar-track { background: #333; }

/* Handle */
::-webkit-scrollbar-thumb { background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; }

@media (max-width: 768px) {
  .body-content {
    max-width: unset;
    width: 100%;
    font-size: 4.5vw;
    padding: 10vw 0 0;
  }

  .header-logo {
    height: 10vw;
    padding: 1vw;
    position: fixed;
    z-index: 9;
    width: 100%;
    background: #fff;

    .header-sidebar-icon {
      height: 5vw;
      position: absolute;
      left: 4vw;
    }
  }

  .m-nav-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .nav-bar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00000080;
      z-index: 1;
    }

    .nav-bar-tabs-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 55vw;
      height: 100%;
      background: #fff;
      padding: 8vw 4vw 4vw;
      line-height: 8vw;
      z-index: 999;

      .nav-bar-tab {
        text-decoration: none;
        color: #000;
        font-size: 5vw;
        margin: 0 0 3vw;
        border-bottom: 0.01vw solid #000;
        padding: 0 0 1vw;
      }
    }
  }

  .nav-bar-container {
    display: none;
  }

  .slider-dots {
    width: 2vw;
    height: 2vw;
    cursor: pointer;
  }

  .standard-component {
    margin: 5vw 0 0;
  }

  .standard-header {
    font-size: 4vw;
    font-weight: 900;

    .standard-header-bottom-line {
      width: 8vw;
      margin: 0.5vw 0 0;
    }
  }

  .standard-body {
    margin: 4vw 0 0;
  }

  .body-footer {
    height: 7vw;
    font-size: 3vw;
  }
}
